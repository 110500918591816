import { generateState } from "arctic";
import { useCookies } from "react-cookie";
import { siteConst } from "@/lib/site-const";

export const useGoogleOAuth = () => {
  const [, setCookie] = useCookies([siteConst.auth.google.GOOGLE_OAUTH_COOKIE]);

  const rootUrl = siteConst.auth.google.GOOGLE_OAUTH_ROOT_AUTH_URL;
  const state = generateState();
  setCookie(siteConst.auth.google.GOOGLE_OAUTH_COOKIE, state, {
    path: "/",
  });

  const options = {
    response_type: "code",
    client_id: import.meta.env.VITE_PUBLIC_GOOGLE_CLIENT_ID!,
    redirect_uri: import.meta.env.VITE_PUBLIC_GOOGLE_REDIRECT_URL!,
    scope: siteConst.auth.google.GOOGLE_OAUTH_SCOPES.join(" "),
    state: state,
    access_type: "offline",
    include_granted_scopes: String(true),
    service: "lso",
    o2v: "1",
    ddm: "1",
    flowName: "GeneralOAuthFlow",
    prompt: "consent",
  };

  const queryString = new URLSearchParams(options).toString();
  const url: string = `${rootUrl}?${queryString}`;
  return url;
};
