import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";

import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useState } from "react";
import { Link } from "react-router";
import { useNavigate } from "react-router";
import { toast } from "sonner";
import { emailSchema, passwordSchema } from "@/lib/auth/zod-schema";
import { useEmailPasswordLogin } from "@/lib/hooks/auth/use-email-password-login";
import { useCookies } from "react-cookie";
import { siteConst } from "@/lib/site-const";

const formSchema = z.object({
  email: emailSchema,
  password: passwordSchema,
});

export default function LoginForm() {
  const [errorMessage, setErrorMessage] = useState("");
  const [, setCookie] = useCookies([siteConst.auth.jwt.JWT_COOKIE]);
  const navigate = useNavigate();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  function useSubmit(values: z.infer<typeof formSchema>) {
    try {
      const res = useEmailPasswordLogin(values.email, values.password);

      res.then((res) => {
        if (res.success && res.jwtToken) {
          setCookie(siteConst.auth.jwt.JWT_COOKIE, res.jwtToken, {
            path: "/",
          });
          toast.success("Logged in successfully");
          navigate("/");
        } else {
          toast.error(res.error);
          setErrorMessage(res.error);
        }
      });
    } catch (error) {
      console.error(error);
      setErrorMessage("Unexpected error occurred");
    }
  }

  return (
    <Card className="mx-auto max-w-sm">
      <CardHeader>
        <CardTitle className="text-2xl">Login</CardTitle>
        <CardDescription>
          Enter your email below to login to your account
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form className="grid gap-4" onSubmit={form.handleSubmit(useSubmit)}>
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input
                      id="email"
                      type="email"
                      placeholder="m@example.com"
                      required
                      data-testid="email-input"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <div className="flex items-center">
                    <FormLabel>Password</FormLabel>
                    <Link
                      to="/request-password"
                      className="ml-auto inline-block text-sm underline"
                    >
                      Forgot your password?
                    </Link>
                  </div>
                  <FormControl>
                    <Input
                      id="password"
                      type="password"
                      required
                      data-testid="password-input"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="text-red-500">
              {errorMessage !== "" && errorMessage}
            </div>
            <Button type="submit" className="w-full">
              Login
            </Button>
            <Link to="/login/google">
              <Button variant="outline" className="w-full" type="button">
                Login via Google
              </Button>
            </Link>
            <Link to="/login/azure">
              <Button variant="outline" className="w-full" type="button">
                Login via Microsoft
              </Button>
            </Link>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
}
