import { emailSchema } from "./zod-schema";

export async function requestPasswordResetToken(
  email: string,
): Promise<{ success: boolean; error: string }> {
  const backendurl = `${import.meta.env.VITE_PUBLIC_BACKEND_URL}/user/password-reset/`;

  try {
    const zodParse = emailSchema.safeParse(email);
    if (!zodParse.success) {
      return { success: false, error: "Please enter a valid email!" };
    }

    const formData = new FormData();
    formData.append("email", zodParse.data);

    const res = await fetch(backendurl, {
      method: "POST",
      body: formData,
    });

    if (res.ok) {
      return { success: true, error: "" };
    } else {
      const errorData = await res.json();
      return {
        success: false,
        error: errorData.error_message || "An unknown error occurred",
      };
    }
  } catch (error) {
    console.error("Unexpected error:", error);
    return { success: false, error: "Unexpected error occurred" };
  }
}
