import { generateState } from "arctic";
import { useCookies } from "react-cookie";
import { siteConst } from "@/lib/site-const";

export const useAzureOAuth = () => {
  const [, setCookie] = useCookies([siteConst.auth.azure.AZURE_OAUTH_COOKIE]);

  const rootUrl = siteConst.auth.azure.AZURE_OAUTH_ROOT_AUTH_URL;
  const state = generateState();
  setCookie(siteConst.auth.azure.AZURE_OAUTH_COOKIE, state, {
    path: "/",
  });

  const options = {
    response_type: "code",
    client_id: import.meta.env.VITE_PUBLIC_MICROSOFT_CLIENT_ID!,
    redirect_uri: import.meta.env.VITE_PUBLIC_MICROSOFT_REDIRECT_URL!,
    scope: siteConst.auth.azure.AZURE_OAUTH_SCOPES.join(" "),
    state: state,
    client_info: "1",
  };

  const queryString = new URLSearchParams(options).toString();
  const url: string = `${rootUrl}?${queryString}`;
  return url;
};
