import { Button } from "@/components/ui/button";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router";
import { toast } from "sonner";

export default function DashboardPage() {
  const [cookies, , removeCookies] = useCookies(["user-acc"]);
  const navigate = useNavigate();

  function handleLogout() {
    removeCookies("user-acc", { path: "/" });
    toast.info("Logged out successfully");
    navigate("/login");
  }

  useEffect(() => {
    if (!cookies["user-acc"]) navigate("/login");
  }, [navigate, cookies]);

  if (cookies["user-acc"]) {
    return (
      <div className="mx-auto mt-10 w-fit space-y-10">
        <p>hey there {cookies["user-acc"]["email"]}</p>
        <pre>{JSON.stringify(cookies["user-acc"], null, 2)}</pre>
        <Button variant="destructive" onClick={() => handleLogout()}>
          Logout
        </Button>
      </div>
    );
  }
}
