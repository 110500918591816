import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";

import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router";
import { toast } from "sonner";
import { passwordSchema } from "@/lib/auth/zod-schema";
import { confirmPasswordReset } from "@/lib/auth/confirm-password-reset";

const formSchema = z
  .object({
    password: passwordSchema,
    confirmPassword: passwordSchema,
  })
  .superRefine((val, ctx) => {
    if (val.password !== val.confirmPassword) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Password is not the same as confirm password",
        path: ["confirmPassword"],
      });
    }
  });

export function ResetPasswordForm() {
  const [errorMessage, setErrorMessage] = useState("");
  const [token, setToken] = useState("");
  const [searchParams] = useSearchParams();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });
  const navigate = useNavigate();

  // check for token in searchParams
  useEffect(() => {
    const tokenParam = searchParams.get("token");
    if (tokenParam === null) {
      navigate("/request-password");
    } else {
      setToken(tokenParam);
    }
  }, [searchParams, navigate]);

  async function onSubmit(values: z.infer<typeof formSchema>) {
    const formData = new FormData();
    formData.append("token", token);
    formData.append("password", values.password);

    const result = await confirmPasswordReset(token, values.password);
    if (result.success) {
      toast.success("Password reset successful. Try logging in now.");
      navigate("/login");
    } else {
      setErrorMessage(result.error);
    }
  }

  return (
    <Card className="mx-auto w-full max-w-sm">
      <CardHeader>
        <CardTitle className="text-2xl">Change your Password</CardTitle>
        <CardDescription>Please enter your new password</CardDescription>
      </CardHeader>
      <CardContent>
        <Form {...form}>
          <form className="grid gap-4" onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>New Password</FormLabel>
                  <FormControl>
                    <Input
                      id="password"
                      type="password"
                      data-testid="password-input"
                      required
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="confirmPassword"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Confirm Password</FormLabel>
                  <FormControl>
                    <Input
                      id="confirmPassword"
                      type="password"
                      required
                      data-testid="confirm-password-input"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="text-red-500">
              {errorMessage !== "" && errorMessage}
            </div>
            <Button type="submit" className="w-full">
              Change Password
            </Button>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
}
