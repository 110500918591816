import { siteConst } from "@/lib/site-const";
import { jwtDecode } from "jwt-decode";
import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate, useSearchParams } from "react-router";
import { toast } from "sonner";

export default function ValidateState({
  oauth_provider,
  oauth_cookie,
}: {
  oauth_provider: string;
  oauth_cookie: string;
}) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [, setCookie] = useCookies([siteConst.auth.jwt.JWT_COOKIE]);
  const [cookies] = useCookies([oauth_cookie]);
  const code = searchParams.get("code") ?? null;
  const error = searchParams.get("error") ?? null;
  const state = searchParams.get("state") ?? null;
  const storedState = cookies[oauth_cookie] ?? null;

  async function handleOAuthLogin(formData: FormData) {
    const backendUrl = `${import.meta.env.VITE_PUBLIC_BACKEND_URL}/login/user/`;
    const res = await fetch(backendUrl, {
      method: "POST",
      body: formData,
    });
    const json = await res.json();
    if (res.ok) {
      const payload = jwtDecode(json.token);
      setCookie(siteConst.auth.jwt.JWT_COOKIE, payload, {
        path: "/",
      });
      toast.success("Logged in successfully");
      navigate("/");
    } else {
      toast.error("An error occurred. Please try again.");
      navigate("/login");
    }
  }

  useEffect(() => {
    if (
      code === null ||
      state === null ||
      storedState === null ||
      storedState !== state ||
      error !== null
    ) {
      navigate("/");
    } else {
      const formData = new FormData();
      formData.append("code", code as string);
      formData.append("identity_provider", oauth_provider);
      handleOAuthLogin(formData);
    }
  }, [navigate]);

  return (
    <>
      <p className="mx-auto mt-10 w-fit">Loading....</p>
    </>
  );
}
