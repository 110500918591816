export const siteConst = {
  auth: {
    jwt: {
      JWT_COOKIE: "user-acc",
    },
    google: {
      GOOGLE_OAUTH_NAME: "google",
      GOOGLE_OAUTH_COOKIE: "google_oauth_cookie",
      GOOGLE_OAUTH_CALLBACK_PATH: "/login/google/callback/*",
      GOOGLE_OAUTH_ROOT_AUTH_URL:
        "https://accounts.google.com/o/oauth2/auth/oauthchooseaccount",
      GOOGLE_OAUTH_SCOPES: [
        "https://www.googleapis.com/auth/userinfo.email",
        "openid",
      ],
    },
    azure: {
      AZURE_OAUTH_NAME: "azure",
      AZURE_OAUTH_COOKIE: "microsoft_oauth_cookie",
      AZURE_OAUTH_CALLBACK_PATH: "/login/azure/callback/*",
      AZURE_OAUTH_ROOT_AUTH_URL:
        "https://login.microsoftonline.com/common/oauth2/v2.0/authorize",
      AZURE_OAUTH_SCOPES: ["https://graph.microsoft.com/.default"],
    },
  },
};
