import { Routes, Route } from "react-router";
import DashboardPage from "./pages/dashboard";
import RequestPasswordPage from "./pages/auth/forgot-password";
import ResetPasswordPage from "./pages/auth/reset-password";
import LoginPage from "./pages/auth/login";
import GoogleOAuthPage from "./pages/auth/google-oauth";
import ValidateState from "./pages/auth/validate-state";
import { siteConst } from "./lib/site-const";
import AzureOAuthPage from "./pages/auth/microsoft-oauth";

export default function App() {
  return (
    <>
      <Routes>
        <Route index element={<DashboardPage />} />
        <Route path="/request-password" element={<RequestPasswordPage />} />
        <Route path="/change-password/*" element={<ResetPasswordPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/login/google" element={<GoogleOAuthPage />} />
        <Route
          path={siteConst.auth.google.GOOGLE_OAUTH_CALLBACK_PATH}
          element={
            <ValidateState
              oauth_provider={siteConst.auth.google.GOOGLE_OAUTH_NAME}
              oauth_cookie={siteConst.auth.google.GOOGLE_OAUTH_COOKIE}
            />
          }
        />
        <Route path="/login/azure" element={<AzureOAuthPage />} />
        <Route
          path={siteConst.auth.azure.AZURE_OAUTH_CALLBACK_PATH}
          element={
            <ValidateState
              oauth_provider={siteConst.auth.azure.AZURE_OAUTH_NAME}
              oauth_cookie={siteConst.auth.azure.AZURE_OAUTH_COOKIE}
            />
          }
        />
      </Routes>
    </>
  );
}
