export async function confirmPasswordReset(
  token: string,
  newPassword: string,
): Promise<{ success: boolean; error: string }> {
  const backendUrl = `${import.meta.env.VITE_PUBLIC_BACKEND_URL}/user/confirm-password/`;

  try {
    const formData = new FormData();
    formData.append("token", token);
    formData.append("password", newPassword);

    const res = await fetch(backendUrl, {
      method: "POST",
      body: formData,
    });

    if (res.ok) {
      return { success: true, error: "" };
    }

    const errorData = await res.json();
    return {
      success: false,
      error: errorData.error_message || "An unknown error occurred",
    };
  } catch (error) {
    console.error("Unexpected error:", error);
    return { success: false, error: "Unexpected error occurred" };
  }
}
