import { jwtDecode, JwtPayload } from "jwt-decode";

export async function useEmailPasswordLogin(
  email: string,
  password: string,
): Promise<{ success: boolean; error: string; jwtToken?: JwtPayload }> {
  const backendUrl = `${import.meta.env.VITE_PUBLIC_BACKEND_URL}/login/user/`;

  try {
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);
    formData.append("identity_provider", "self");

    const res = await fetch(backendUrl, {
      method: "POST",
      body: formData,
    });

    if (res.ok) {
      const json = await res.json();
      const payload = jwtDecode(json.token);

      return { success: true, error: "", jwtToken: payload };
    }

    const errorData = await res.json();
    return {
      success: false,
      error: errorData.error_message || "An unknown error occurred",
    };
  } catch (error) {
    console.error("Unexpected error:", error);
    return { success: false, error: "Unexpected error occurred" };
  }
}
